.batch-line-table {
    border: 1px solid #ccc;
}

.batch-line-table .ant-table-thead .ant-table-cell {
    border-radius: 0;
}

.batch-line-table .ant-table-thead .ant-table-cell {
    background-color: #70bf45 !important;
    color: #fff;
}
