.flag-table .ant-table-body th {
    background-color: #f0f0f0 !important; /* This sets the background color to antd gray-4 */
}

.flag-table .ant-table-body tr:nth-child(even) td {
    background-color: #f0f0f0 !important; /* This sets the background color to antd gray-4 */
}

.flag-table .ant-form-item {
    margin-bottom: 8px !important;
}

.flag-table .constraint-button span {
    padding: 0px !important;
    width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.flag-table .batch-button span {
    padding: 0px !important;
    width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.flag-table .flow-button span {
    padding: 0px !important;
    width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
}


