.shift-table .ant-table-body th {
  background-color: #f0f0f0 !important; /* This sets the background color to antd gray-4 */
}

.shift-table .ant-table-body tr:nth-child(even) td {
  background-color: #f0f0f0 !important; /* This sets the background color to antd gray-4 */
}

.shift-table .ant-form-item {
  margin-bottom: 8px !important;
}
