.code128-scanner-modal {
  display: flex;
  justify-content: center;
}

.code128-scanner-modal .drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.code128-scanner-modal .scanner-container {
  display: flex;
  justify-content: center;
  z-index: 2;
  position: relative;
  height: 480px;
  width: 640px;
}

.code128-scanner-modal .mask {
  height: 480px;
  width: 640px;
  border-left: 130px solid rgba(0, 0, 0, 0.5);
  border-right: 130px solid rgba(0, 0, 0, 0.5);
  border-top: 50px solid rgba(0, 0, 0, 0.5);
  border-bottom: 50px solid rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.code128-scanner-modal .mask .line-container {
  position: relative;
  width: 100%;
  height: 5px;
}

.code128-scanner-modal .mask .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: red;
}

.code128-scanner-modal .mask .point {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  animation: movePoint 1s linear infinite;
}

@keyframes movePoint {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
