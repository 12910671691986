.batch-table .batch-descriptions .ant-descriptions-row {
  display: block;
  height: min-content;
}

.batch-table .ant-table-expanded-row {
  background-color: #f0f0f0;
}

.batch-table .ant-table .ant-expanded-row-parent > td {
  font-weight: bold;
  background-color: rgba(138, 193, 108, 0.2);
}
