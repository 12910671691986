.photo-capture-modal.ant-modal {
    width: min-content !important;
    height: min-content !important;
    top: 50px;
}

.photo-capture-modal .ant-modal-content {
    width: min-content;
}

.photo-capture-modal .ant-modal-body {
    width: min-content;
}

.photo-capture-modal .photo-capture-button-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.photo-capture-modal .photo-capture-button-container button{
    background-color: white;
    border-radius: 100px;
    height: 60px;
    width: 60px;
    margin: 15px;
    outline: white 5px solid;
    border: solid 2px black;
}
